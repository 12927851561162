import React from 'react';
import { normalizeString } from '../OrderStatusLineItem/orderStatusUtils';

function MyAccountNavNew({
  name,
  active,
  myStylePrefToggle,
  dtMyStoreToggle,
  unifiedPrefToggle,
}) {
  const myStoreHref = dtMyStoreToggle
    ? '/my/favorites/store'
    : '/myfavorites/myFavorites.jsp?view=favs&forOmniLN=1';
  return (
    <ul className="nav">
      <li>
        <span className="myGlobalSignedIn" tabIndex="0">
          {name ? (
            <>
              {'Hello '}
              {normalizeString(name)}
              &nbsp;
            </>
          ) : (
            <a href="/login">Sign In / Register</a>
          )}
        </span>
      </li>
      <li className="myFav">
        <a
          id="myAcctNavAO"
          className={active === 'myAcctNavAO' ? 'active' : 'changeOver'}
          href={
            active === 'myAcctNavAO'
              ? '/accountoverview'
              : `${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav`
          }
        >
          Account Overview
        </a>
      </li>
      <li className="myFav">
        <a
          id="myAcctNavOH"
          className={active === 'myAcctNavOH' ? 'active' : 'changeOver'}
          href={active === 'myAcctNavOH' || '/order-history'}
        >
          Order History
        </a>
      </li>
      <li className="myFav">
        <a
          id="myNMCreditCard"
          className="changeOver"
          href={name ? '/my/NMCreditCard' : '/my/Loyalty'}// new uca loyalty hub page
        >
          NM Credit Card
        </a>
      </li>
      {unifiedPrefToggle && name && (
        <li className={'myFavStore'}>
          <a
            id="myCommPref"
            className="changeOver"
            href={'/my/preferences/communication'}
          >
            Communication Preferences
          </a>
        </li>
      )}
      <li className="myFav">
        <a
          id="myAcctNavAB"
          className="changeOver"
          href={`${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav&view=address&forOmniLN=1`}
        >
          Address Book
        </a>
      </li>
      <li className="myFav">
        <a
          id="myAcctNavPI"
          className="changeOver"
          href={`${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav&view=pay&forOmniLN=1`}
        >
          Payment Information
        </a>
      </li>

      <li className="myFav">
        <a
          id="myFavNavFI"
          className={active === 'myFavNavFI' ? 'active' : 'changeOver'}
          href="/myfavorites/myFavoriteItems.jsp?view=favItems&forOmniLN=1&favin=1"
        >
          Favorite Items
        </a>
      </li>
      {myStylePrefToggle && (
        <li className="myFav">
          <a
            id="myFavNavMSP"
            href="/my/preferences/stylepreferences"
            className={active === 'myFavNavMSP' ? 'active' : 'changeOver'}
          >
            My Style Preferences
          </a>
        </li>
      ) }
      <li className={'myFavStore'}>
        <a id="myFavNavFS" className="changeOver" href={myStoreHref}>
          My Store
        </a>
      </li>


    </ul>
  );
}

export default MyAccountNavNew;
